<template>
  <el-form class="form-add-new width-all"
           :model="dataMap"
           ref="formNameAddNew"
           v-loading="loading">
    <template>

      <el-form-item label="品牌代理级别">
        <el-select v-model="dataMap.agentLevel"
                   placeholder="请选择"
                   style="width: 217px">
          <el-option
            v-for="item in branAgentLevelArray"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <template v-if="!isNoBrandStatus">
        <div class="flex-item-form-header">
          <div class="content">品牌信息</div>
        </div>
        <template v-if="!dataMap.id">
          <el-form-item label="选择品牌"
                        label-width="100px">
            <el-select
              v-model="brandSelectValue"
              filterable
              remote
              reserve-keyword
              default-first-option
              placeholder="输入品牌名称"
              @change="selectChangeBrand"
              :remote-method="searchBrand"
              :loading="loadingBrand">
              <el-option
                v-for="item in listBrand"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-button type="" icon="el-icon-edit" class="margin-left " @click="createdBrand">自定义品牌</el-button>
          </el-form-item>
          <el-form-item label="">
            <el-alert type="error" style="line-height: initial;">
              <template slot="title">
                <div class="" style="font-size: 16px;">请搜索或者自定义您要入驻的品牌</div>
                <div class="color-info" style="margin-top: 6px;">搜 索 品 牌：1、输入品牌名称  --> 2、上下移动选择品牌后点击回车。</div>
                <div class="color-info" style="margin-top: 6px;">自定义品牌：1、点击“自定义品牌” --> 2、编辑品牌名称、上传品牌图标。</div>
              </template>
            </el-alert>
          </el-form-item>
        </template>
        <el-table v-if="dataMap.brandId" :data="[dataMap]" class="margin-bottom">
          <el-table-column
            prop="brandId"
            label="品牌ID"
            width="200">
            <template slot-scope="scope">
              {{scope.row.brandId}}
            </template>
          </el-table-column>
          <el-table-column
            prop="brandName"
            label="品牌名称"
            width="200">
            <template slot-scope="scope">
              <template v-if="scope.row.isNew">
                <el-input v-model="dataMap.brandName" maxlength="20"/>
              </template>
              <template v-else-if="scope.row.brandName">
                {{scope.row.brandName}}
              </template>
              <template v-else>
                暂无品牌
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="图标"
            width="100">
            <template slot-scope="scope">
              <template v-if="scope.row.isNew">
                <upload-images-one :file.sync="dataMap.brandLogo"
                                   width="60px"
                                   :files-size="50"
                                   :limit="1"
                                   files-size-unit="kb"></upload-images-one>
              </template>
              <template v-else>
                <img v-if="scope.row.brandLogo" :src="scope.row.brandLogo" alt="" class="table-row-image-60"/>
              </template>
            </template>
          </el-table-column>
          <el-table-column  align="right">
            <template slot="header"></template>
            <template slot-scope="scope">
            </template>
          </el-table-column>
        </el-table>
      </template>

      <template v-if="isNoBrandStatus || (!isNoBrandStatus && (dataMap.isNew ? (dataMap.brandName && dataMap.brandLogo) : dataMap.brandId ))">
        <div class="flex-item-form-header">
          <div class="content">商品分类</div>
          <!--<el-button class="right" type="" size="mini" icon="el-icon-delete" @click="clearSelectCategory">一键清空</el-button>-->
          <el-button class="right" type="danger" size="mini" icon="el-icon-plus" @click="showSelectCategory">选择分类</el-button>
        </div>

        <el-table :data="categoryList"
                  class="margin-bottom"
                  style="width: 100%">
          <el-table-column
            type="index"
            label="序号"
            width="60">
          </el-table-column>
          <el-table-column
            prop="firstCateName"
            label="一级分类"
            width="120">
            <template slot-scope="scope">
              {{scope.row.firstCateName}}
            </template>
          </el-table-column>
          <el-table-column
            prop="secCateName"
            label="二级分类"
            width="120">
            <template slot-scope="scope">
              {{scope.row.secCateName}}
            </template>
          </el-table-column>
          <el-table-column
            prop="thirdCateName"
            label="三级分类">
            <template slot-scope="scope">
              {{scope.row.thirdCateName}}
            </template>
          </el-table-column>
          <el-table-column align="right"
                           width="120">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" circle icon="el-icon-delete" @click="clearTwoCategory(scope.row.secCateId)"></el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="flex-item-form-header">
          <div class="content">品牌资质</div>
        </div>
      </template>

    </template>

    <div class="text-center margin-top padding-top">
      <template>
        <el-button type="primary" @click="submitForm('formNameAddNew','review')" >提交审核</el-button>
      </template>
    </div>

    <el-dialog
      title="选择分类"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :visible.sync="visibleSelectCategory"
      width="30%">
      <el-cascader class="width-all"
                   :value="categoryIds"
                   ref="categorySelect"
                   collapse-tags
                   :options="listCategory"
                   :props="{value:'id',label:'name',multiple: false,expandTrigger: 'hover'}"
                   v-if="visibleSelectCategory"></el-cascader>
      <div class="text-right margin-top">
        <el-button type="info" @click="hideSelectCategory">取消</el-button>
        <el-button type="primary" @click="selectChangeCategory">确认</el-button>
      </div>
    </el-dialog>

  </el-form>

</template>

<script>
  import {deepsClone, isEmpty,JSONClone} from '../../assets/js/utils'
  import {URL} from '../../config'
  import {branAgentLevelArray} from '../../assets/js/status'
  import {disposeTreeData} from '../../assets/js/goodsClassify'
  import * as formRules from '../../assets/js/formRules'
  import uploadImagesOne from '../../components/public/upload/uploadImagesOne'

  function returnBaseData(){
    return {
      isNew:false,// 是否是自定义
      agentLevel: 100,
      brandId: '',
      brandName:'',
      brandLogo:'',
      cateList: [
      ],
    }
  }
  export default {
    name: 'brandEdit',
    components:{
      uploadImagesOne,
    },
    props:{
      data:Object,
    },
    data(){
      return {
        loading:false,
        formRules,
        // 深拷贝参数
        dataMap:returnBaseData(),
        // 品牌
        loadingBrand:false,
        listBrand: [],
        brandSelectValue:'', // ['id']
        // 分类
        visibleSelectCategory:false,
        hasLoadCategory:false,
        listCategory:[],
        listCateGoryOrigin:[],
        branAgentLevelArray,
      }
    },
    created(){
      if(!isEmpty(this.data)){
        this.dataGetMap(this.data)
      }
      this.loadCategory();
    },
    methods:{
      /**
       * 处理 dataMap
       */
      dataGetMap(data){
        if(isEmpty(data)){
          this.brandSelectValue='';
          this.dataMap = returnBaseData();
        }else {
          this.dataMap = deepsClone(data);
        }
        return this.dataMap;
      },
      /**
       * 自定义品牌
       */
      createdBrand(){
        this.brandSelectValue = '';
        this.dataMap = Object.assign({},returnBaseData(),{
          id:this.dataMap.id,
          isNew:true,
          brandId:'0',
          agentLevel:this.dataMap.agentLevel,
        });
      },
      /**
       * 搜索品牌列表
       */
      searchBrand(query){
        if (query === '') {
          this.listBrand = [];
          return false
        }
        this.loadingBrand = true;
        this.axios.get(URL.supplierBrand.searchCommonBrand,{params:{name:query}}).then(res=>{
          this.listBrand = res.data
        }).finally(()=>{
          setTimeout(()=>{ this.loadingBrand = false },2e2)
        })
      },
      /**
       * 选择品牌发生变化
       */
      selectChangeBrand(now){
        this.matchSelectChangeBrand(now)
      },
      matchSelectChangeBrand(id = this.brandSelectValue){
        let list = this.listBrand.filter(res => res.id === id)
        if(!isEmpty(list)) this.pushTableDataBrand(list[0])
      },
      pushTableDataBrand(row = {}){
        this.dataMap.isNew = false;
        this.dataMap.brandId = row.id;
        this.dataMap.brandName = row.name;
        this.dataMap.brandLogo = row.logo;
      },
      /**
       * 搜索分类列表
       */
      loadCategory(){
        this.axios.get(URL.supplierBrand.searchCommonCategory,{params:{}}).then(res=>{
          if(res.code === 0){
            this.listCateGoryOrigin= res.data;
            this.listCategory = disposeTreeData(res.data,2);
          }
        }).catch(()=>{
        })
      },
      /**
       * 选择分类发生变化
       */
      showSelectCategory(){
        this.visibleSelectCategory = true
      },
      hideSelectCategory(){
        this.visibleSelectCategory = false
      },
      clearTwoCategory(secCateId){
        if(!secCateId) return false;
        this.dataMap.cateList = this.dataMap.cateList.filter(res=>res.secCateId !== secCateId);
      },
      selectChangeCategory(){
        let selectList = this.$refs.categorySelect.getCheckedNodes();
        if(!(Array.isArray(selectList) && selectList.length)) {
          return this.$message.error('请选择分类')
        }
        let selectData = selectList[0];
        let nodes = selectData.pathNodes.map(res=>res.data);
        let names = selectData.pathLabels;
        let ids = nodes.map(res=>res.id);
        let threeList = this.filterThreeCategory(selectData.value).filter(res=>!this.dataMap.cateList.map(res=>res.thirdCateId).includes(res.id)).map(res=>{
          return {
            firstCateName:names[0],
            firstCateId:ids[0],
            secCateName:names[1],
            secCateId:ids[1],
            thirdCateName:res.name,
            thirdCateId:res.id,
          }
        });
        this.pushTableDataCategory(threeList);
        this.hideSelectCategory()
      },
      filterThreeCategory(twoId = ''){
        if(!twoId) return [];
        return this.listCateGoryOrigin.filter(res=>res.parentId === twoId);
      },
      pushTableDataCategory(data){
        let list = [];
        if(data instanceof Array){
          if(!isEmpty(data)) list.push(...data)
        }else if(typeof data === 'object'){
          if(!isEmpty(data)) list.push(data)
        }else{
          return false
        }
        return this.dataMap.cateList.push(...list)
      },
      /**
       * 提交审核
       */
      addNew(){
        this.loading = true
        this.axios.post(URL.supplierBrand.addSupplierBrand,this.dataMap).then(res=>{
          this.$emit('success',{data:this.dataMap,type:'saveReview',msg:'提交审核成功'})
        }).catch(res=>{

        }).finally(res=>{
          this.loading = false
        })
      },

      /**
       * 表单提交及重置
       * @param formName
       * @param type    {string}    draft-保存草稿 review-审核
       */
      submitForm(formName,type = 'draft') {
        if(isEmpty(this.dataMap.cateList)){
          return this.$message.error('请选择经营的商品分类')
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.addNew()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    },
    computed:{
      isNoBrandStatus(){
        return this.dataMap.agentLevel === 100;
      },
      categoryList(){
        let list = [];
        let cateList = this.dataMap.cateList;
        if(Array.isArray(cateList)){
          if(cateList.length){
            let ids = cateList.map(res=>res.secCateId);
            ids = Array.from(new Set(ids));
            ids.forEach(id=>{
              let filterList = cateList.filter(res=>res.secCateId === id);
              let first = filterList[0];
              let li = JSONClone(first);
              li.thirdCateName = filterList.map(res=>res.thirdCateName).join(',');
              list.push(li);
            });
          }
        }
        return list;
      },
      categoryIds(){
        let list = [];
        let cateList = this.dataMap.cateList;
        if(Array.isArray(cateList)){
          if(cateList.length){
            let first = cateList[cateList.length - 1];
            list = [first.firstCateId,first.secCateId];
          }
        }
        return list;
      },

    },
    watch:{
      data:function (newValue) {
        console.log('%c ===============> 父组件传值变化','color:red',newValue);
        this.dataGetMap(newValue)
      },
    }
  }
</script>

<style lang="less" scoped>
  @import (reference) "../../assets/css/data";
  .table-list{
    width: 100%;
    margin-bottom: @margin-primary;
  }
</style>
