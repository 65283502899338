<template>
  <div class="">
    <div class="flex-item-form-header">
      <div class="content">品牌</div>
    </div>
    <el-table :data="tableDataBrand"
              class="table-list">
      <el-table-column
        prop="brandName"
        label="品牌名称"
        width="100">
      </el-table-column>
      <el-table-column
        label="品牌图标"
        width="80">
        <template slot-scope="scope">
          <img v-if="scope.row.brandLogo" :src="scope.row.brandLogo" alt="" class="block table-row-image-60"/>
        </template>
      </el-table-column>
      <el-table-column
        prop="brandId"
        label="品牌ID">
      </el-table-column>
    </el-table>

    <div class="flex-item-form-header">
      <div class="content">商品分类</div>
    </div>
    <el-table :data="categoryList"
              class="table-list">
      <el-table-column
        prop="firstCateName"
        label="一级分类"
        width="120">
      </el-table-column>
      <el-table-column
        prop="secCateName"
        label="二级分类"
        width="120">
      </el-table-column>
      <el-table-column
        prop="thirdCateName"
        label="三级分类">
      </el-table-column>
    </el-table>

    <template v-if="data.agentLevel !== 100">
      <div class="flex-item-form-header">
        <div class="content">品牌资质</div>
      </div>
      <el-form-item label="品牌代理级别">
        {{branAgentLevel[data.agentLevel]}}
      </el-form-item>
    </template>

  </div>
</template>

<script>
  import {JSONClone} from "../../assets/js/utils";
  import {branAgentLevel,branAgentLevelArray} from '../../assets/js/status'
  export default {
    name: 'brandInfoDetailView',
    props:{
      data:Object,
    },
    data(){
      return {
        // 代理级别
        branAgentLevel,
        branAgentLevelArray,
        branAgentLevelValue:1,
      }
    },
    computed: {
      tableDataBrand: function () {
        return this.data ? [{
          brandName: this.data.brandName,
          brandLogo: this.data.brandLogo,
          brandId: this.data.brandId,
        }] : []
      },
      categoryList(){
        let list = [];
        let cateList = this.data.cateList;
        if(Array.isArray(cateList)){
          if(cateList.length){
            let ids = cateList.map(res=>res.secCateId);
            ids = Array.from(new Set(ids));
            ids.forEach(id=>{
              let filterList = cateList.filter(res=>res.secCateId === id);
              let first = filterList[0];
              let li = JSONClone(first);
              li.thirdCateName = filterList.map(res=>res.thirdCateName).join(',');
              list.push(li);
            });
          }
        }
        return list;
      },
    }
  }
</script>

<style scoped>

</style>
