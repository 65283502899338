<template>
  <el-drawer
    class="drawer-scroll-y"
    title="品牌入驻详情"
    ref="drawerNameEdit"
    :withHeader="true"
    :visible.sync="showDrawer"
    :size="dataMap.reviewStatus === 2?'calc(40%)':'calc(80%)'">
    <div class="drawer-scroll-y-box">
      <el-form
        class="form-add-new width-all"
        :model="dataMap"
        ref="formNameAddNew"
        label-width="120px"
        v-loading="loading">
        <div class="contrast-wrap" v-if="dataMap.reviewStatus !== 2">
          <div class="contrast-box contrast-before">
            <p class="contrast-header">使用中</p>
            <div class="contrast-body">
              <brand-info-detail-view :data="brandOldInfo" v-if="brandOldInfo"></brand-info-detail-view>
            </div>
          </div>
          <div class="contrast-box  contrast-after">
            <p class="contrast-header">新申请</p>
            <div class="contrast-body">
              <brand-info-detail-view :data="brandNewInfo" v-if="brandNewInfo"></brand-info-detail-view>
            </div>
          </div>
        </div>
        <div class="contrast-wrap" v-else>
          <div class="contrast-body">
            <brand-info-detail-view :data="brandNewInfo" v-if="brandNewInfo"></brand-info-detail-view>
          </div>
        </div>

        <el-form-item label="审核备注" v-if="dataMap.reviewRemark" class="color-danger">
          {{data.reviewRemark}}
        </el-form-item>

      </el-form>
    </div>
  </el-drawer>

</template>

<script>
  import {deepsClone, isEmpty} from '../../assets/js/utils'
  import brandInfoDetailView from './brandInfoDetailView'

  export default {
    name: 'editBrand',
    components: {
      brandInfoDetailView
    },
    props: {
      data: Object,
      type: {
        type: Number,
        default: 1,
      },
      visible: Boolean,
    },
    data() {
      return {
        loading: false,
        dataMap: {},// 深拷贝参数
        brandOldInfo: null,
        brandNewInfo: null,
        showDrawer: false,
      }
    },
    created() {
      this.showDrawer = this.visible;
      if (!isEmpty(this.data)) {
        this.dataGetMap(this.data)
      }
    },
    methods: {
      /**
       * 处理 dataMap
       */
      dataGetMap(data) {
        Object.assign(this.dataMap,data);
        this.brandNewInfo = deepsClone(data.brandNewInfo)
        this.brandOldInfo = deepsClone(data.brandOldInfo)
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    },
    computed: {
      tableDataBrand: function () {
        let data = this.dataMap
        return data.brandId ? [{
          brandName: data.brandName,
          brandLogo: data.brandLogo,
          brandId: data.brandId,
        }] : []
      },
    },
    watch: {
      data: function (newValue) {
        this.dataGetMap(newValue)
      },
      visible(newValue) {
        this.showDrawer = newValue;
      },
      showDrawer(newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  }
</script>

<style lang="less" scoped>
  @import (reference) "../../assets/css/data";

  .table-list {
    width: 100%;
    margin-bottom: @margin-primary;
  }
</style>
