<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-select v-model="searchData.reviewStatus"
                       @change="search"
                       placeholder="请选择"
                       style="width: 120px">
              <el-option
                v-for="item in searchKeyType"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input class=""
                      placeholder="品牌名称"
                      prefix-icon="el-icon-search"
                      v-model="searchData.brandName"
                      @keyup.enter.native="search"
                      style="width: 260px">
              <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button class="" type="primary" icon="el-icon-plus"  @click="showAddNew">新增</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:table="row">
        <el-table
          :data="tableData"
          :height="row.heightText"
          v-if="row.height">
          <el-table-column
            prop="brandId"
            label="品牌ID"
            width="200">
          </el-table-column>
          <el-table-column
            prop="brandLogo"
            label="品牌图标"
            width="100">
            <template slot-scope="scope">
              <img v-if="scope.row.brandLogo" :src="scope.row.brandLogo" alt="" class="table-row-image-60"/>
            </template>
          </el-table-column>
          <el-table-column
            prop="brandName"
            label="品牌名称"
            width="200">
          </el-table-column>
          <el-table-column
            label="品牌代理商级别"
            width="200">
            <template slot-scope="scope">
              {{branAgentLevel[scope.row.agentLevel]}}
            </template>
          </el-table-column>
          <el-table-column
            label="审核状态"
            width="100">
            <template slot-scope="scope">
              {{brandReviewStatus[scope.row.reviewStatus]}}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-view"  @click="getBrandInfo(scope.row.id)">详情</el-button>
              <el-button type="danger" size="mini" icon="el-icon-edit" @click="editBrandInfo(scope.row.id)" v-if="scope.row.reviewStatus !== 1">类目新增及管理</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>

      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="parseInt(totalListNum)">
          </el-pagination>
        </div>
      </template>
    </table-height-auto>

    <brand-info :visible.sync="visibleShowAll" :data="showAllData"></brand-info>

    <el-drawer
      class="drawer-scroll-y"
      title="编辑"
      ref="drawerNameEdit"
      :withHeader="true"
      :wrapperClosable="false"
      :closeOnPressEscape="false"
      :visible.sync="visibleAddNew"
      @closed="hideAddNew"
      size="700px">
      <div class="drawer-scroll-y-box">
        <brand-edit :data="formAddNew" @success="addNewEditSuccess"></brand-edit>
      </div>
    </el-drawer>

  </div>
</template>

<script>
  import {baseURL, URL, URL_MINOR} from '../../../config'
  import {isHTMLElement, objectRemoveEmpty} from '../../../assets/js/utils'
  import {branAgentLevel, brandReviewStatus, brandReviewStatusArray, disabledStatus} from '../../../assets/js/status'
  import brandInfo from '../../../components/brand/brandInfo'
  import brandEdit from "../../../components/brand/brandEdit";

  export default {
    name: 'brand',
    components:{
      brandEdit,
      brandInfo,
    },
    data(){
      return {
        loading:false,
        /* 数据表格 */
        searchKeyType:brandReviewStatusArray,
        disabledStatus,
        brandReviewStatus,
        branAgentLevel,
        searchData:{
          reviewStatus:0,
          brandName:'',
        },
        tableData: [], // 数据列表
        currentPage: 1, // 当前页码
        totalListNum: 0, // 列表总条量
        pageSize: 10, // 每页显示得条数
        visibleAddNew:false,
        formAddNew:{},
        visibleShowAll:false,
        showAllData:{},
        /* 文件上传 */
        uploadImg:'',
        uploadData:{
          uploadUrl: baseURL + URL_MINOR.upload.uploadImage,
          headers:{
            token:this.$store.state.token,
          },
        },
      }
    },
    created(){
      this.getList();
      this.parseQuery();
    },
    methods:{
      parseQuery(){
        let params = this.$route.query;
        if(params['showDrawer'] === '1'){
          this.showAddNew();
        }
      },
      /**
       * 拼接搜索参数
       */
      jointSearchData(type){
        let data = {
          pageNo:this.currentPage,
          pageSize:this.pageSize,
        };
        data = Object.assign(data,this.searchData);
        objectRemoveEmpty(data)
        return data;
      },
      /**
       * 搜索
       */
      search(type){
        this.currentPage = 1;
        this.getList()
      },
      /**
       * 查询列表数据
       */
      getList(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.supplierBrand.supplierBrandList,{params:data}).then(res=>{
            this.totalListNum = res.data.total;
            this.tableData = res.data.records;
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
            // reject(res);
          }).finally(res=>{
            setTimeout(res=>{ this.loading = false },2e2)
          })
        })
      },
      /**
       * pageSize 改变时会触发：选择每页显示条数
       */
      handleSizeChange(val,type){
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      /**
       * currentPage 改变时会触发：选择页数
       */
      handleCurrentChange(val,type){
        this.currentPage = val;
        this.getList();
      },
      /**
       * 获取申请入驻品牌详情
       */
      getBrandInfo(id){
        this.loading = true;
        this.axios.get(URL.supplierBrand.supplierBrandInfo,{params:{id:id}}).then(res=>{
          if(res.code === 0){
            this.showAllData = res.data;
            this.visibleShowAll = true;
          }
        }).finally(()=>{
          this.loading = false
        })
      },
      /**
       * 显示 编辑属性组弹窗
       * @param data  {object}  //编辑时需要传入编辑的数据对象
       * @param type  {number}  //编辑类型
       * */
      showAddNew(){
        this.formAddNew = {};
        this.visibleAddNew = true
      },
      editBrandInfo(id){
        this.loading = true;
        this.axios.get(URL.supplierBrand.supplierBrandUsing,{params:{id:id}}).then(res=>{
          if(res.code === 0){
            this.formAddNew = res.data;
            this.visibleAddNew = true
          }
        }).finally(()=>{
          this.loading = false
        })
      },
      hideAddNew(){
        this.formAddNew = {};
        if(this.visibleAddNew) this.visibleAddNew = false;
      },
      addNewEditSuccess(){
        this.getList()
        this.hideAddNew()
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
  }
</script>

<style lang="less">
  @import (reference) "../../../assets/css/data";
  .el-upload-list__item,
  .el-upload--picture-card{
    @height:80px;
    width: @height;
    height: @height;
    line-height: @height - 2px;
  }
</style>
